import frFR from './fr-fr.common.json'

type Locale = {
  code: 'fr-fr'
  name: string
}

const locales: Locale[] = [
  {
    code: 'fr-fr',
    name: 'France',
  },
]

export default {
  defaultLocale: locales[0].code,
  locales,
  messages: { fr: frFR },
  parsePages: false,
  detectBrowserLanguage: {
    useCookie: false,
  },
}
